import mushroom from 'mushroomjs';

"use strict";

// --------- helper methods ------------
function setStorageItem(key, value) {
    if (value !== null && value !== undefined && typeof value !== "string") {
        value = value.toString();
    }

    localStorage.setItem(key, value);
}

function getStorageItem(key) {
    return localStorage.getItem(key);
}

function removeStorageItem(key) {
    return localStorage.removeItem(key);
}

function getAllStorageKeys() {
    return Object.keys(localStorage);
}
// --------- end helper methods ------------

async function fireEvent(eventName, args) {
    if (typeof eventName !== "string") throw new Error("eventName must be a string");
    if (eventName === "") throw new Error("eventName cannot be empty");

    if (!events[eventName])
        return;

    for (let i = 0; i < events[eventName].length; i++) {
        const evt = events[eventName][i];
        const r = evt.apply(mushroom, args === undefined ? [] : args);
        if (r instanceof Promise)
            await r;
    }
}

var tokenPool = {};
var events = {};

mushroom.$auth = mushroom.$auth || {};

// số phút lưu trữ token nếu inactive
mushroom.$auth.expiredTime = 30;
// cho biết đã khởi tạo xong chưa
mushroom.$auth.isReady = false;

mushroom.$auth.on = (eventName, fnHandler) => {
    if (typeof eventName !== "string") throw new Error("eventName must be a string");
    if (eventName === "") throw new Error("eventName cannot be empty");
    if (typeof fnHandler !== "function") throw new Error("fnHandler must be a callback function");

    var eventList = events[eventName];
    if (!eventList) eventList = events[eventName] = new Array();
    eventList.push(fnHandler);
    return this;
};


function saveToken(rootApiUrl, token, permanent) {
    try {
        deleteToken(rootApiUrl);
        //console.log("deleted Token");
        //console.log("call setStorageItem(%o, %o)", "mushroom.tokens[" + rootApiUrl + "]", token);
        setStorageItem("mushroom.tokens[" + rootApiUrl + "]", token);
        //console.log("set Token");
        //var s = getStorageItem("mushroom.tokens[" + rootApiUrl + "]");
        //console.log("getStorageItem: token=" + s);
        if (!permanent)
            setStorageItem("mushroom.tokens[" + rootApiUrl + "].expired", new Date().getTime() + mushroom.$auth.expiredTime * 60000 + "");
    }
    catch (ex) {
        console.warn(ex);
    }
}

function deleteToken(rootApiUrl) {
    removeStorageItem("mushroom.tokens[" + rootApiUrl + "]");
    removeStorageItem("mushroom.tokens[" + rootApiUrl + "].expired");
}

function loadTokens() {
    try {
        var keys = getAllStorageKeys();
        //console.log("loadToken, keys.length=", keys.length);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            //console.log("loadToken, key=", key);
            if (key && key.indexOf("mushroom.tokens[") === 0 && key.charAt(key.length - 1) === "]") {
                var expired = getStorageItem(key + ".expired");
                if (expired) {
                    expired = parseInt(expired);
                    if (isNaN(expired) || expired < new Date().getTime()) {
                        continue;
                    }
                }
                var apiUrl = key.substring("mushroom.tokens[".length, key.length - 1);
                tokenPool[apiUrl] = getStorageItem(key);
                //console.log("loadToken, set tokenPool[", apiUrl, "]=", tokenPool[apiUrl]);
            }
        }
    } catch (e) {
        console.warn(e);
    }
    mushroom.$auth.isReady = true;
    //console.log("loadToken: tokenPool=", tokenPool);
    fireEvent("ready");
}

loadTokens();



mushroom.$auth.loginAsync = (account, password, remember) => {
    var rootApiUrl = mushroom._fnGetRootApiUrl();
    return mushroom.__createAsyncRestFunction({
        name: "$auth.loginAsync",
        method: "POST",
        blankBody: false,
        url: rootApiUrl + "auth/login"
    })({
        account: account,
        password: password
    }).then(response => {
        //console.log("login success, response: %o", response);
        tokenPool[rootApiUrl] = response.result.token;
        try {
            saveToken(rootApiUrl, tokenPool[rootApiUrl], remember);
        }
        catch (e) {
            console.warn("Cannot save token: %o", e);
        }

        fireEvent("loggedin");

        return response;
    });
};
mushroom.$auth.logoutAsync = (options) => {
    if (typeof options === "boolean") {
        options = {
            mode: options ? "invalidAllSession" : "invalidServerSession"
        };
    }
    else if (typeof options !== "object" || options === null) {
        options = {
            mode: "invalidServerSession"
        };
    }

    if (options.mode !== "invalidClientSession" &&
        options.mode !== "invalidServerSession" &&
        options.mode !== "invalidAllSession") {
        options.mode = "invalidServerSession";
    }

    // mode: invalidClientSession, invalidServerSession, invalidAllSession

    var rootApiUrl = mushroom._fnGetRootApiUrl();

    if (options.mode === "invalidClientSession") {
        delete tokenPool[rootApiUrl];
        return new Promise((resolve) => {
            deleteToken(rootApiUrl);
            if (mushroom.$cache)
                mushroom.$cache.invalid();
            resolve({ code: 0 });
        });
    }
    else
        return mushroom.__createAsyncRestFunction({
            name: "$auth.logoutAsync",
            method: "GET",
            blankBody: false,
            url: rootApiUrl + "auth/logout" + (options.mode === "invalidAllSession" ? "?invalidAllSession=true" : "")
        })().then(x => {
            delete tokenPool[rootApiUrl];
            deleteToken(rootApiUrl);
            if (mushroom.$cache)
                mushroom.$cache.invalid();
            fireEvent("loggedout");
            return x;
        }).catch(err => {
            delete tokenPool[rootApiUrl];
            deleteToken(rootApiUrl);
            if (mushroom.$cache)
                mushroom.$cache.invalid();
            throw err;
        });
};

function generateStatusUrl() {
    return mushroom._fnGetRootApiUrl() + "auth/status";
}

mushroom.$auth.statusAsync = mushroom.__createAsyncRestFunction({
    name: "$auth.statusAsync",
    method: "GET",
    blankBody: false,
    url: generateStatusUrl
});

function generateMeUrl() {
    return mushroom._fnGetRootApiUrl() + "auth/me";
}

mushroom.$auth.meAsync = mushroom.__createAsyncRestFunction({
    name: "$auth.meAsync",
    method: "GET",
    blankBody: false,
    url: generateMeUrl
});

mushroom.$auth.registerAsync = (account, password) =>
    mushroom.__createAsyncRestFunction({
        name: "$auth.registerAsync",
        method: "POST",
        blankBody: false,
        url: mushroom._fnGetRootApiUrl() + "auth/register"
    })({
        account: account,
        password: password
    });


mushroom.$auth.activateAsync = (account, code) =>
    mushroom.__createAsyncRestFunction({
        name: "$auth.activateAsync",
        method: "POST",
        blankBody: false,
        url: mushroom._fnGetRootApiUrl() + "auth/activate"
    })({
        account: account,
        code: code
    });

mushroom.$auth.recoverPasswordAsync = (account) =>
    mushroom.__createAsyncRestFunction({
        name: "$auth.recoverPasswordAsync",
        method: "POST",
        blankBody: false,
        url: mushroom._fnGetRootApiUrl() + "auth/recover-password"
    })({
        account: account
    });

mushroom.$auth.changePasswordAsync = (account, oldPassword, newPassword) =>
    mushroom.__createAsyncRestFunction({
        name: "$auth.changePasswordAsync",
        method: "POST",
        blankBody: false,
        url: mushroom._fnGetRootApiUrl() + "auth/reset-password"
    })({
        account: account,
        oldPassword: oldPassword,
        newPassword: newPassword
    });

mushroom.$auth.resetPasswordAsync = (account, code, newPassword) =>
    mushroom.__createAsyncRestFunction({
        name: "$auth.resetPasswordAsync",
        method: "POST",
        blankBody: false,
        url: mushroom._fnGetRootApiUrl() + "auth/reset-password"
    })({
        account: account,
        code: code,
        newPassword: newPassword
    });

//console.log("register beforeSend");

mushroom._on("beforeSend",
    request => {
        //console.log("beforeSend: ", tokenPool);
        var rootApiUrl = mushroom._fnGetRootApiUrl();
        if (tokenPool[rootApiUrl]) {
            try {
                let expired = getStorageItem(`mushroom.tokens[${rootApiUrl}].expired`);
                if (expired) {
                    expired = parseInt(expired);
                    if (isNaN(expired) || expired < new Date().getTime()) {
                        deleteToken(rootApiUrl);
                        return;
                    }
                    setStorageItem(`mushroom.tokens[${rootApiUrl}].expired`, new Date().getTime() + mushroom.$auth.expiredTime * 60000 + "");
                }

                request.headers.Token = tokenPool[rootApiUrl];
                //console.log(request);
            }
            catch (ex) {
                console.warn(ex);
            }
        }
    });

export default mushroom;
