let state = {};

state.phanHoi = {
    refreshFnCountNew: 0,
};

state.benh = {
    dialogForm: {},
};

export default state;